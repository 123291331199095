const KpiDeployment = () => import("@/views/other/kpiDeployment/KpiDeploymentIndex.vue")

const moduleBasePath = "/admin/kpi_deployment/"

const routes = [
    {
        path: `${moduleBasePath}`,
        name: "kpiDeployment",
        component: KpiDeployment,
        meta: {
            label: "Kpi Deployment"
        }
    }
]

export default routes
