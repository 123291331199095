<template>
    <div class="default-layout-container">
        <div class="page-header-wrapper" v-if="pageName || pageDescriptionToDisplay">
            <div class="page-header-container" v-if="pageName">
                <el-page-header v-if="goBackRouteName" @back="goBack()">
                    <template #content>
                        <h3 class="text-h5 font-weight-bold">{{ pageName }}</h3>
                    </template>
                </el-page-header>
                <h3 class="text-h5 font-weight-bold" v-else>{{ pageName }}</h3>
                <slot name="cta"></slot>
            </div>
            <p class="text-subtitle-2" v-if="pageDescriptionToDisplay">{{ pageDescriptionToDisplay }}</p>
        </div>
        <slot></slot>
    </div>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import { useRouter } from "vue-router"
import { useIndexStore } from "@/store/core/index"

const router = useRouter()
const indexStore = useIndexStore()
const props = defineProps<{
    pageName?: string
    goBackRouteName?: string
    description?: string
}>()
const pageDescriptionToDisplay = computed(() => props.description || indexStore.pageDescription)

const goBack = (): void => {
    router.push({ name: props.goBackRouteName })
}
</script>

<style lang="scss" scoped>
.default-layout-container {
    padding: 40px 30px;
    flex: 1;
    max-height: 100%;
    overflow-y: auto;
}
.page-header-wrapper {
    margin-bottom: 28px;
    .page-header-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .text-subtitle-2 {
        margin-top: 4px;
    }
}

@media screen and (min-width: 1800px) {
    .default-layout-container {
        padding: 40px 5vw;
    }
}
</style>
<style>
.page-header-container .el-page-header__title {
    line-height: 28px;
}
</style>
