const MatchIndex = () => import("@/views/match/MatchIndex.vue")

export default [
    {
        path: `/admin/match`,
        name: "matchIndex",
        component: MatchIndex,
        meta: {
            label: "Match"
        }
    }
]
