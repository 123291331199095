import Api from "@/lib/Api"

interface State {
    transitPasses: any
}

export default {
    state: {
        transitPasses: null
    } as State,
    mutations: {
        updateTransitPasses(state: State, transitPasses: any) {
            state.transitPasses = transitPasses
        }
    },
    actions: {
        getTransitPasses({ commit }: { commit: Function }) {
            return new Promise((resolve, reject) => {
                Api.get("/admn/transit_passes/")
                    .then(transitPasses => {
                        commit("updateTransitPasses", transitPasses)
                        resolve(transitPasses)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getTransitPassesToValidate() {
            return new Promise((resolve, reject) => {
                Api.get("/admn/transit_passes_to_validate/")
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        updateTransitPassToValidate({}, params: any): Promise<void> {
            return new Promise((resolve, reject) => {
                Api.post("/admn/transit_pass_validation/", params)
                    .then(() => {
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }
    },
    getters: {
        getTransitPasses: (state: State) => state.transitPasses
    }
}
