import { CarpoolRole, UserInterest } from "@/models/core"
import {
    PhonePlateform,
    TicketGroup,
    TicketingRouteConf,
    UserGender,
    ValidationType,
    TableHeader
} from "@/models/crm/ticketing"

const COMMON_HEADERS_TABLE: TableHeader[] = [
    {
        title: "Status",
        key: "state",
        sortable: false
    },
    {
        title: "Id",
        key: "id",
        sortable: false
    },
    {
        title: "User",
        key: "user",
        sortable: false
    },
    {
        title: "Tag",
        key: "tags",
        sortable: false
    },
    {
        title: "Preview",
        key: "last_message_preview",
        sortable: false
    },
    {
        title: "Assignee",
        key: "assignee",
        sortable: false
    }
]
const HEADERS_TABLE_SELF: TableHeader[] = [
    ...COMMON_HEADERS_TABLE,
    {
        title: "Pending",
        key: "pending_since",
        sortable: false
    }
]
const HEADERS_TABLE_OPEN_OR_UNASSIGNED: TableHeader[] = [
    ...COMMON_HEADERS_TABLE,
    {
        title: "Creation",
        key: "creation_date",
        sortable: false
    }
]
const HEADERS_TABLE_ON_HOLD: TableHeader[] = [
    {
        title: "Id",
        key: "id",
        sortable: false
    },
    {
        title: "User",
        key: "user",
        sortable: false
    },
    {
        title: "Assignee",
        key: "assignee",
        sortable: false
    },
    {
        title: "On-hold Reason",
        key: "on_hold_reason",
        sortable: false
    },
    {
        title: "Helper",
        key: "on_hold_helper",
        sortable: false
    },
    {
        title: "Reminder",
        key: "reminder_date",
        sortable: false
    }
]
const HEADERS_TABLE_SOLVED: TableHeader[] = [
    ...COMMON_HEADERS_TABLE,
    {
        title: "Closure",
        key: "solved_date",
        sortable: false
    }
]

const GROUPE_SELF = "my-tickets"
const GROUPE_OPEN = "open"
const GROUPE_ON_HOLD = "on-hold"
const GROUPE_SOLVED = "solved"
const GROUPE_UNASSIGNED = "unassigned"

export const TICKETING_CONF_PER_GROUP: Map<TicketGroup | string, TicketingRouteConf> = new Map()
TICKETING_CONF_PER_GROUP.set(GROUPE_SELF, {
    shortTitle: "My tickets",
    longTitle: "My tickets",
    description: "Answer tickets from here.",
    icon: "mdi-account-circle",
    countKey: "my_tickets_count",
    tableHeaders: HEADERS_TABLE_SELF,
    hasAssigneeFilter: false,
    stateFilterDefaultValues: ["open", "reopen", "on_hold"],
    ticketStateOptions: ["open", "reopen", "on_hold", "answered"]
})
TICKETING_CONF_PER_GROUP.set(GROUPE_OPEN, {
    shortTitle: "Open",
    longTitle: "Open tickets",
    description: "Check here all tickets awaiting response.",
    icon: "mdi-hand-wave",
    countKey: "open_count",
    tableHeaders: HEADERS_TABLE_OPEN_OR_UNASSIGNED,
    hasAssigneeFilter: true,
    stateFilterDefaultValues: [],
    ticketStateOptions: ["new", "open", "on_hold", "reopen"]
})
TICKETING_CONF_PER_GROUP.set(GROUPE_ON_HOLD, {
    shortTitle: "On-hold",
    longTitle: "On-hold tickets",
    description: "Find suspended tickets here, which cannot be resolved immediately.",
    icon: "mdi-clock",
    countKey: "on_hold_count",
    tableHeaders: HEADERS_TABLE_ON_HOLD,
    hasAssigneeFilter: true
})
TICKETING_CONF_PER_GROUP.set(GROUPE_SOLVED, {
    shortTitle: "Solved",
    longTitle: "Solved tickets",
    description: "Ticket will be permanently closed in 2 days.",
    icon: "mdi-briefcase-check",
    countKey: "solved_count",
    tableHeaders: HEADERS_TABLE_SOLVED,
    hasAssigneeFilter: true
})
TICKETING_CONF_PER_GROUP.set(GROUPE_UNASSIGNED, {
    shortTitle: "Unassigned",
    longTitle: "Unassigned tickets",
    description: "Manage ticket assignment from here.",
    icon: "mdi-account-off",
    countKey: "unassigned_count",
    tableHeaders: HEADERS_TABLE_OPEN_OR_UNASSIGNED,
    hasAssigneeFilter: false,
    stateFilterDefaultValues: [],
    ticketStateOptions: ["new", "reopen", "open", "answered", "on_hold"]
})

const USER_GENDER_MAP: Record<UserGender, string> = {
    male: "Man",
    female: "Woman",
    diverse: "Diverse",
    unknown: "Unkown"
}

const PHONE_PLATEFORM_ICONS: Record<PhonePlateform, string> = {
    android: "mdi-android",
    ios: "mdi-apple"
}

const INTEREST_ICON: Record<CarpoolRole, string> = {
    driver: "mdi-steering",
    passenger: "mdi-seat-passenger"
}

const VALIDATION_TYPES_LABELS: Record<ValidationType, string> = {
    email: "Email",
    manual: "Manual",
    none: "None",
    password: "Password",
    password_and_work_geofencing: "Password and work geofencing",
    home_address_and_geofencing: "Home address and geofencing",
    geolocation_home: "Geolocation home",
    geolocation_work: "Geolocation work",
    geolocation_home_or_work: "Geolocation home or work",
    geofencing_work: "Geofencing work",
    geolocation_home_and_work: "Geolocation home and work"
}

const TICKET_TABLE_ITEMS_PER_PAGE_OPTION = [
    { value: 10, title: "10" },
    { value: 25, title: "25" },
    { value: 50, title: "50" },
    { value: 100, title: "100" },
    { value: 500, title: "500" }
]

export default {
    TICKETING_CONF_PER_GROUP,
    GROUPE_SELF,
    GROUPE_OPEN,
    GROUPE_ON_HOLD,
    GROUPE_SOLVED,
    GROUPE_UNASSIGNED,
    USER_GENDER_MAP,
    PHONE_PLATEFORM_ICONS,
    INTEREST_ICON,
    VALIDATION_TYPES_LABELS,
    TICKET_TABLE_ITEMS_PER_PAGE_OPTION
}

export const LABEL_MACRO_ALL_AGENTS = "All agents"
export const LABEL_MACRO_ME_ONLY = "Me only"
