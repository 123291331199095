import Api from "@/lib/Api"

interface State {
    karosEvents: Array<any>
}

export default {
    state: {
        karosEvents: []
    } as State,
    mutations: {
        updateKarosEvents(state: State, events: any) {
            state.karosEvents = events
        }
    },
    actions: {
        syncKarosEvents({ commit }: { commit: Function }) {
            return new Promise((resolve, reject) => {
                Api.get("/event_links/")
                    .then(karosEvents => {
                        commit("updateKarosEvents", (karosEvents as any).results)
                        resolve(karosEvents)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },
        deleteKarosEvent({ commit }: { commit: Function }, event: any) {
            return new Promise((resolve, reject) => {
                Api.patch(`/event_links/${event.link}/`, { is_active: false })
                    .then(karosEvents => {
                        commit("updateKarosEvents", (karosEvents as any).results)
                        resolve(karosEvents)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },
        createKarosEvent({ dispatch }: { dispatch: Function }, eventToCreate: any) {
            return new Promise((resolve, reject) => {
                eventToCreate.creation_origin = "ADMIN"
                Api.post("/event_links/", eventToCreate)
                    .then(createdKarosEvent => {
                        dispatch("syncKarosEvents")
                        resolve(createdKarosEvent)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },
        getBulkEventPreview({}, bulkEventFormData: any) {
            bulkEventFormData.append("confirm", false)
            bulkEventFormData.append("creation_origin", "ADMIN")
            const headers = {}
            return new Promise((resolve, reject) => {
                Api.post("/add_bulk_events/", bulkEventFormData, headers)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(JSON.parse(err.response))
                    })
            })
        },
        createKarosEventBulk({}, bulkEventFormData: any) {
            bulkEventFormData.append("confirm", true)
            bulkEventFormData.append("creation_origin", "ADMIN")
            return new Promise((resolve, reject) => {
                const headers = {}
                Api.post("/add_bulk_events/", bulkEventFormData, headers)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(() => {
                        reject()
                    })
            })
        }
    },
    getters: {
        getKarosEvents: (state: State) => state.karosEvents
    }
}
