const LoginApiIndex = () => import("@/views/Login/Login.vue")
const AccountRecovery = () => import("@/views/Login/AccountRecovery.vue")
const ResetPassword = () => import("@/views/Login/ResetPassword.vue")

export default [
    {
        path: "/admin/login",
        component: LoginApiIndex,
        name: "LoginApiIndex"
    },
    {
        path: "/admin/ask_reset_password_email",
        component: AccountRecovery,
        name: "AccountRecovery"
    },
    {
        path: "/admin/reset_password",
        component: ResetPassword,
        name: "ResetPassword"
    }
]
