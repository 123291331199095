import api from "@/lib/Api"
import { getFormattedErrorResponse } from "@/lib/utils"

interface State {
    pricingAreas: any
    pricingRules: any
}

export default {
    state: {
        pricingAreas: [],
        pricingRules: []
    },
    mutations: {
        updatePricingAreas(state: State, areas: any) {
            state.pricingAreas = areas
        },
        updatePricingRules(state: State, pricingRules: any) {
            state.pricingRules = pricingRules
        },
        addPricingRule(state: State, createdPricingRule: any) {
            state.pricingRules.push(createdPricingRule)
        },
        updatePricingRule(state: State, updatedRule: any) {
            const updatedRuleIndex = state.pricingRules.findIndex((rule: any) => rule.id === updatedRule.id)
            if (updatedRuleIndex > -1) {
                state.pricingRules[updatedRuleIndex] = updatedRule
            }
        },
        deletePricingRule(state: State, ruleToDeleteId: any) {
            const ruleToDeleteIndex = state.pricingRules.findIndex((rule: any) => rule.id === ruleToDeleteId)
            if (ruleToDeleteIndex > -1) {
                state.pricingRules.splice(ruleToDeleteIndex, 1)
            }
        }
    },
    actions: {
        syncPricingAreas({ commit }: { commit: Function }) {
            return new Promise((resolve, reject) => {
                api.get("/admn/pricing_areas/")
                    .then(pricingAreas => {
                        commit("updatePricingAreas", pricingAreas)
                        resolve(pricingAreas)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        createPricingArea({}, pricingAreaToCreate: any) {
            return new Promise((resolve, reject) => {
                api.post("/admn/pricing_areas/", pricingAreaToCreate)
                    .then(createdPricingArea => {
                        resolve(createdPricingArea)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        updatePricingAreas({}, { id, pricingArea }: any) {
            return new Promise((resolve, reject) => {
                api.patch(`/admn/pricing_areas/${id}/`, pricingArea)
                    .then(updatedPricingArea => {
                        resolve(updatedPricingArea)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        deletePricingArea({}, pricingAreaId: any): Promise<void> {
            return new Promise((resolve, reject) => {
                api.delete(`/admn/pricing_areas/${pricingAreaId}/`)
                    .then(() => {
                        resolve()
                    })
                    .catch((err: any) => {
                        reject(err)
                    })
            })
        },

        syncDefaultPricingRule() {
            return new Promise((resolve, reject) => {
                api.get("/admn/pricing_rule_default/")
                    .then(defaultPricingRule => {
                        resolve(defaultPricingRule)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        syncPricingRules({ commit }: { commit: Function }, args: any) {
            let endpoint = "/admn/pricing_rules/?"
            for (const [key, val] of Object.entries(args)) {
                endpoint += `${key}=${val}&`
            }
            return new Promise((resolve, reject) => {
                api.get(endpoint)
                    .then(pricingRules => {
                        commit("updatePricingRules", pricingRules)
                        resolve(pricingRules)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        createPricingRule({ commit }: { commit: Function }, pricingRule: any) {
            return new Promise((resolve, reject) => {
                api.post("/admn/pricing_rules/", pricingRule)
                    .then(createdPricingRule => {
                        commit("addPricingRule", createdPricingRule)
                        resolve(createdPricingRule)
                    })
                    .catch(err => {
                        reject(getFormattedErrorResponse(err))
                    })
            })
        },
        updatePricingRule({ commit }: { commit: Function }, pricingRule: any) {
            return new Promise((resolve, reject) => {
                api.patch(`/admn/pricing_rules/${pricingRule.id}/`, pricingRule)
                    .then(updatedPricingRule => {
                        commit("updatePricingRule", updatedPricingRule)
                        resolve(updatedPricingRule)
                    })
                    .catch(err => {
                        reject(getFormattedErrorResponse(err))
                    })
            })
        },
        deletePricingRule({ commit }: { commit: Function }, pricingRuleId: any): Promise<void> {
            return new Promise((resolve, reject) => {
                api.delete(`/admn/pricing_rules/${pricingRuleId}/`)
                    .then(() => {
                        commit("deletePricingRule", pricingRuleId)
                        resolve()
                    })
                    .catch((err: any) => {
                        reject(getFormattedErrorResponse(err))
                    })
            })
        }
    },
    getters: {
        getPricingAreas: (state: State) => state.pricingAreas,
        getPricingRules: (state: State) => state.pricingRules
    }
}
