const DriverLicenseValidation = () => import("@/views/crm/DriverLicenseValidation.vue")

export default [
    {
        path: "/admin/driver-license-validation",
        component: DriverLicenseValidation,
        name: "driverLicenseValidationIndex",
        meta: {
            label: "Driver License validation"
        }
    }
]
