import Api from "@/lib/Api"

export default {
    actions: {
        relaunchPayment({}, data: any) {
            return new Promise((resolve, reject) => {
                Api.post("/relaunch_payment/", data)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err.response)
                    })
            })
        }
    }
}
