import Api from "@/lib/Api"

export default {
    actions: {
        getChatroomMessages({}, queryParameters: any) {
            return new Promise((resolve, reject) => {
                Api.get(`/chat_moderation/?${queryParameters}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }
    }
}
