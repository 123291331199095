const UserChatroom = () => import("@/views/various/UserChatroom.vue")

export default [
    {
        path: `/admin/user_room_messages`,
        name: "userChatroom",
        component: UserChatroom,
        meta: {
            label: "Users chatroom"
        }
    }
]
