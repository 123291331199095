const CeeAudit = () => import("@/views/crm/CeeAudit.vue")

const moduleBasePath = "/admin/cee_audit/"

const routes = [
    {
        path: `${moduleBasePath}`,
        name: "ceeAudit",
        component: CeeAudit,
        meta: {
            label: "CEE",
            description: "Manage the validation of user information for CEE file submission."
        }
    }
]

export default routes
