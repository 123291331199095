import api from "@/lib/Api"
import moment from "@/lib/moment"
import { replacePTagsByBr } from "@/lib/utils"
import { router } from "@/router/index"

interface State {
    flashInfos: any
    selectedFlashInfo: any
    preview: any
    flashInfoPendingForm: any
}

const flashInfos = {
    state: {
        flashInfos: [],
        selectedFlashInfo: null,
        preview: {
            picture_url: "",
            title: "",
            content: "",
            headers: [],
            cta_label: ""
        },
        flashInfoPendingForm: null
    } as State,
    mutations: {
        updateFlashInfos(state: State, flashInfos: any) {
            state.flashInfos = flashInfos
            sortFlashInfos(state.flashInfos)
        },
        updateFlashInfo(state: State, flashInfo: any) {
            const flashInfoIndex = state.flashInfos.findIndex((f: any) => f.id === flashInfo.id)
            if (flashInfoIndex !== -1) {
                state.flashInfos[flashInfoIndex] = flashInfo
            } else {
                state.flashInfos.push(flashInfo)
            }
        },
        updateSelectedFlashInfo(state: State, selectedFlashInfo: any) {
            if (!selectedFlashInfo) return
            state.selectedFlashInfo = { ...selectedFlashInfo }

            const route = router.currentRoute.value
            if (parseInt(route.params.id as any) !== selectedFlashInfo.id) {
                router.replace({ params: { id: selectedFlashInfo.id || "draft" } })
            }
            state.preview = {
                picture_url: state.selectedFlashInfo.picture_url,
                title: state.selectedFlashInfo.title,
                content: state.selectedFlashInfo.content,
                headers: [],
                cta_color: state.selectedFlashInfo.cta_color,
                cta_label: state.selectedFlashInfo.cta_label
            }
            if (selectedFlashInfo.header_picture_one_name) {
                state.preview.headers.push(selectedFlashInfo.header_picture_one_url)
                if (selectedFlashInfo.header_picture_two_name) {
                    state.preview.headers.push(selectedFlashInfo.header_picture_two_url)
                }
            }
        },
        deleteFlashInfoItem(state: State, flashInfoItem: any) {
            state.flashInfos = state.flashInfos.filter((flashInfo: any) => flashInfo.id !== flashInfoItem.id)
        },
        selectCreationPendingFlashInfo(state: State) {
            state.selectedFlashInfo = state.flashInfos[state.flashInfos.length - 1]
            state.preview = {
                picture_url: state.selectedFlashInfo.picture_url,
                title: state.selectedFlashInfo.title,
                content: state.selectedFlashInfo.content,
                headers: [],
                cta_color: state.selectedFlashInfo.cta_color,
                cta_label: state.selectedFlashInfo.cta_label
            }
        },
        deleteCreationPendingFlashInfo(state: State) {
            state.flashInfos = state.flashInfos.filter((flashInfo: any) => Number.isInteger(flashInfo.id))
            state.selectedFlashInfo = state.flashInfos[state.flashInfos.length - 1]
        },
        saveDraftFlashInfo(state: State, flashInfo: any) {
            state.flashInfos[state.flashInfos.length - 1] = flashInfo
        },
        updateFlasInfoPreview(state: State, { key, val }: any) {
            state.preview[key] = val
        },
        updateFlashInfoPendingForm(state: State, pendingFlashInfo: any) {
            pendingFlashInfo.title = replacePTagsByBr(pendingFlashInfo.title)
            pendingFlashInfo.content = replacePTagsByBr(pendingFlashInfo.content)
            state.flashInfoPendingForm = {
                ...pendingFlashInfo,
                territory_ids: pendingFlashInfo.territory_ids && pendingFlashInfo.territory_ids
            }
        }
    },
    actions: {
        syncFlashInfos({ commit }: { commit: Function }) {
            return new Promise((resolve, reject) => {
                api.get("/admn/flash_infos/")
                    .then(flashInfos => {
                        commit("updateFlashInfos", flashInfos)
                        resolve(flashInfos)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },
        getFlashInfoDetails({ commit }: { commit: Function }, id: Number) {
            return new Promise((resolve, reject) => {
                api.get(`/admn/flash_infos/${id}/`)
                    .then(flashInfo => {
                        commit("updateFlashInfo", flashInfo)
                        resolve(flashInfo)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },
        createFlashInfo({ commit, state }: { commit: Function; state: State }, flashInfo: any) {
            return new Promise((resolve, reject) => {
                formatFlashInfoData(flashInfo)
                api.post("/admn/flash_infos/", flashInfo)
                    .then(createdFlashInfo => {
                        commit("updateFlashInfo", createdFlashInfo)
                        sortFlashInfos(state.flashInfos)
                        commit("updateSelectedFlashInfo", createdFlashInfo)
                        resolve(createdFlashInfo)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },
        updateFlashInfo({ commit, state }: { commit: Function; state: State }, flashInfo: any) {
            return new Promise((resolve, reject) => {
                formatFlashInfoData(flashInfo)
                api.patch(`/admn/flash_infos/${flashInfo.id}/`, flashInfo)
                    .then(updatedFlashInfo => {
                        commit("updateFlashInfo", updatedFlashInfo)
                        sortFlashInfos(state.flashInfos)
                        commit("updateSelectedFlashInfo", updatedFlashInfo)
                        resolve(updatedFlashInfo)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },
        deleteFlashInfo({ commit, state }: { commit: Function; state: State }, flashInfo: any): Promise<void> {
            return new Promise((resolve, reject) => {
                api.delete(`/admn/flash_infos/${flashInfo.id}/`)
                    .then(() => {
                        commit("deleteFlashInfoItem", flashInfo)
                        commit("updateSelectedFlashInfo", state.flashInfos[state.flashInfos.length - 1])
                        resolve()
                    })
                    .catch((err: any) => {
                        console.error(err)
                        reject(err)
                    })
            })
        },
        syncFlashInfoCountUsers({}, payload: any) {
            return new Promise((resolve, reject) => {
                api.post("/admn/flash_info_count_users/", payload)
                    .then((res: any) => {
                        resolve(res.nb_users)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        }
    },
    getters: {
        getFlashInfos: (state: State) => state.flashInfos,
        getSelectedFlashInfo: (state: State) => state.selectedFlashInfo,
        getFlashInfoPreview: (state: State) => state.preview,
        getFlashInfoPendingForm: (state: State) => state.flashInfoPendingForm
    }
}

function sortFlashInfos(flashInfos: any) {
    flashInfos.sort((flash1: any, flash2: any) => {
        if (!flash1.start_date) return 1
        else if (!flash2.start_date) return -1
        else {
            return moment(flash1.start_date).isBefore(moment(flash2.start_date)) ? -1 : 1
        }
    })
}

function formatFlashInfoData(flashInfo: any) {
    for (const [key, value] of Object.entries(flashInfo)) {
        if (Array.isArray(value)) {
            flashInfo[key] = value
        }
    }
}

export default flashInfos
