import { TICKETING_CONF_PER_GROUP } from "@/constants/crm/ticketing"
import { TicketGroup, TicketingRouteConf } from "@/models/crm/ticketing"
import { computed } from "vue"
import { useRoute } from "vue-router"

export function useRouteName() {
    const route = useRoute()
    const routeName = computed<string>(() => {
        return route.name?.toString() || ""
    })

    return { routeName }
}

export function useRouteUserUuid() {
    const route = useRoute()
    const userUuid = computed<string>(() => {
        return route.params.userUuid as string
    })

    return { userUuid }
}

export const useTicketingGroup = () => {
    const route = useRoute()
    const ticketGroup = computed<TicketGroup>(() => {
        return route.params.group as TicketGroup
    })

    const currentTicketGroupConf = computed<TicketingRouteConf | undefined>(() =>
        TICKETING_CONF_PER_GROUP.get(ticketGroup.value)
    )

    return { ticketGroup, currentTicketGroupConf }
}
