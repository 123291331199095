import Api from "@/lib/Api"

export default {
    actions: {
        getMatch(context: any, matchUuid: any) {
            return new Promise((resolve, reject) => {
                Api.get(`/admn/matches/${matchUuid}/`)
                    .then(match => {
                        resolve(match)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(new Error(err))
                    })
            })
        },
        getLocations(context: any, { userUuid, minDate, maxDate }: any) {
            return new Promise((resolve, reject) => {
                Api.get(`/admn/locations_view/?user_uuid=${userUuid}&min_date=${minDate}&max_date=${maxDate}`)
                    .then(locations => {
                        resolve(locations)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(new Error(err))
                    })
            })
        },
        getMatchReceipt(context: any, { isDriver, matchUuid }: any) {
            return new Promise((resolve, reject) => {
                Api.get(`/admn/matches/${matchUuid}/receipt/?is_driver=${isDriver}`)
                    .then(matchReceipt => {
                        resolve(matchReceipt)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(new Error(err))
                    })
            })
        },
        getTicketCheckLogs(context: any, match_uuid: any) {
            return new Promise((resolve, reject) => {
                Api.get(`/ticket_check_logs/?match_uuid=${match_uuid}`)
                    .then(ticketCheckLogs => {
                        resolve(ticketCheckLogs)
                    })
                    .catch(err => {
                        reject(new Error(err))
                    })
            })
        },
        getDriverPaymentEvents(context: any, { userUuid, matchUuid }: any) {
            const endpoint = `/admn/payment_events/?show_hidden=true&user_uuid=${userUuid}&match_uuid=${matchUuid}`
            return new Promise((resolve, reject) => {
                Api.get(endpoint)
                    .then(paymentEvents => {
                        resolve(paymentEvents)
                    })
                    .catch(err => {
                        reject(new Error(err))
                    })
            })
        },
        getMatchesWithPaymentRelaunched(context: any, { driverUuid, passengerUuid }: any) {
            return new Promise((resolve, reject) => {
                Api.get(
                    `/search_matchs_with_payment_relaunched/?driver_uuid=${driverUuid}&passenger_uuid=${passengerUuid}`
                )
                    .then(matches => {
                        resolve(matches)
                    })
                    .catch(err => {
                        reject(new Error(err))
                    })
            })
        },
        getMatchPricingRulesCheck(context: any, matchUuid: any) {
            return new Promise((resolve, reject) => {
                Api.get(`/admn/match_pricing_rules_check/?match_uuid=${matchUuid}`)
                    .then(pricingRules => {
                        resolve(pricingRules)
                    })
                    .catch(err => {
                        reject(new Error(err))
                    })
            })
        },
        recreditDriver(context: any, eventId: any): Promise<void> {
            return new Promise((resolve, reject) => {
                Api.post(`/admn/cancel_payment_event/?event_id=${eventId}`)
                    .then(() => {
                        resolve()
                    })
                    .catch(err => {
                        reject(new Error(err))
                    })
            })
        }
    }
}
