import { RouteRecordRaw } from "vue-router"
import accountManagerProfiles from "@/router/companiesAndTerritories/accountManager"
import partnerMap from "@/router/companiesAndTerritories/partnerMap"
import partners from "@/router/companiesAndTerritories/partners"
import pricingRules from "@/router/companiesAndTerritories/pricingRules"
import mobilityPackage from "@/router/companiesAndTerritories/mobilityPackage"
import transitPass from "@/router/companiesAndTerritories/transitPass"
import geojsonGenerator from "@/router/companiesAndTerritories/geojsonGenerator"
import partnerMatchListing from "@/router/companiesAndTerritories/partnerMatchListing"

export const companiesAndTerritoriesRoutes: RouteRecordRaw[] = [
    ...accountManagerProfiles,
    ...partnerMap,
    ...partners,
    ...pricingRules,
    ...mobilityPackage,
    ...transitPass,
    ...geojsonGenerator,
    ...partnerMatchListing
]

export default companiesAndTerritoriesRoutes
