import COUNTRY_CONFS from "@/constants/country"

export const GET_COUNTRY_CONF = (country: string | null) => {
    if (!country) country = "fr"
    return COUNTRY_CONFS[country]
}

export const GET_TEAMSPACE_LOGO = (country: string | null) => {
    const countryConf = GET_COUNTRY_CONF(country)
    if (!countryConf) return
    return `/assets/${countryConf.appName}/teamspace_logo.svg`
}
