<template>
    <v-snackbar v-model="isVisible" max-width="520" :color="type === ERROR ? 'red-darken-3' : 'green-darken-4'">
        <div class="snackbar__content-container">
            <v-icon size="16" :icon="type === ERROR ? 'mdi-alert-circle' : 'mdi-check-circle'"></v-icon>
            <span class="text-body-1">
                <slot></slot>
            </span>
        </div>
    </v-snackbar>
</template>

<script setup lang="ts">
import { ref, watch } from "vue"
import { SnackbarType } from "@/models/core"
const ERROR = "error"

const props = defineProps<{
    modelValue: boolean
    type: SnackbarType
}>()
const emit = defineEmits(["update:model-value"])

const isVisible = ref(props.modelValue)
watch(
    () => props.modelValue,
    () => {
        isVisible.value = props.modelValue
    }
)
watch(isVisible, () => {
    if (!isVisible.value) {
        emit("update:model-value", isVisible.value)
    }
})
</script>

<style lang="scss" scoped>
.snackbar__content-container {
    display: flex;
    align-items: center;
    column-gap: 20px;
}
</style>
