import Api from "@/lib/Api"

export default {
    actions: {
        syncConfigs() {
            return new Promise((resolve, reject) => {
                Api.get("/admn/share_configs/")
                    .then(shareConfigs => {
                        resolve(shareConfigs)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },
        createShareConfig({}, configToCreate: any) {
            return new Promise((resolve, reject) => {
                Api.post("/admn/share_configs/", configToCreate)
                    .then(createdConfig => {
                        resolve(createdConfig)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },
        updateShareConfig({}, configToUpdate: any) {
            return new Promise((resolve, reject) => {
                Api.patch(`/admn/share_configs/${configToUpdate.id}/`, configToUpdate)
                    .then(updatedShareConfig => {
                        resolve(updatedShareConfig)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },
        uploadShareConfigImage({}, data: any) {
            return new Promise((resolve, reject) => {
                const headers = {}
                Api.post("/admn/share_img/", data, headers)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        }
    }
}
