import Api from "@/lib/Api"
import { FavoritePage, MenuGroupValue } from "@/models/menus/menu"
import { defineStore } from "pinia"
import { ref } from "vue"

export const useMenuStore = defineStore("menu", () => {
    const isMenuExpanded = ref(true)
    const toggleMenuExpansion = () => {
        isMenuExpanded.value = !isMenuExpanded.value
        railModeChanged(!isMenuExpanded.value)
    }

    const openMenus = ref<MenuGroupValue[]>([])
    const openMenusBackup = ref<MenuGroupValue[]>([])
    const openFavoriteMenus = ref<string[]>([])
    const openFavoriteMenusBackup = ref<string[]>([])

    const railModeChanged = (isRail: boolean) => {
        if (isRail) {
            openMenusBackup.value = [...openMenus.value]
            openMenus.value = []

            openFavoriteMenusBackup.value = [...openFavoriteMenus.value]
            openFavoriteMenus.value = []
        } else {
            openMenus.value = [...openMenusBackup.value]
            openFavoriteMenus.value = [...openFavoriteMenusBackup.value]
        }
    }

    const favorites = ref<FavoritePage[]>([])
    const getFavoritePages = (): Promise<FavoritePage[]> => {
        return new Promise((resolve, reject) => {
            Api.get("/admn/favorite_pages/")
                .then((fetchedFavorites: FavoritePage[]) => {
                    favorites.value = fetchedFavorites
                    resolve(fetchedFavorites)
                })
                .catch(err => {
                    favorites.value = []
                    reject(err)
                })
        })
    }
    const addFavorite = (payload: Omit<FavoritePage, "id">): Promise<FavoritePage> => {
        return new Promise((resolve, reject) => {
            Api.post("/admn/favorite_pages/", payload)
                .then((createdFavorite: FavoritePage) => {
                    favorites.value.push(createdFavorite)
                    resolve(createdFavorite)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }
    const deleteFavorite = (id: number): Promise<void> => {
        return new Promise((resolve, reject) => {
            Api.delete(`/admn/favorite_pages/${id}/`)
                .then(() => {
                    favorites.value = favorites.value.filter(fav => fav.id !== id)
                    resolve()
                })
                .catch((err: any) => {
                    reject(err)
                })
        })
    }

    return {
        openMenus,
        openFavoriteMenus,
        isMenuExpanded,
        toggleMenuExpansion,
        railModeChanged,

        favorites,
        getFavoritePages,
        addFavorite,
        deleteFavorite
    }
})
