const GeojsonGenerator = () => import("@/views/other/GeojsonGenerator.vue")

const moduleBasePath = "/admin/geojson_generator/"

const routes = [
    {
        path: `${moduleBasePath}`,
        name: "geojsonGenerator",
        component: GeojsonGenerator,
        meta: {
            label: "Geojson Generator"
        }
    }
]

export default routes
