import ListStorage from "@/lib/ListStorage"
import Api from "@/lib/Api"

const DefaultList = {
    id: "",
    name: "",
    tags: [],
    date: new Date().toISOString(),
    isFetched: false,
    user_uuid_list: []
}

interface State {
    list: any
    users: any
}

const CALL_INTERFACE = {
    state: {
        list: {
            ...DefaultList
        }
    } as State,
    mutations: {
        updateListName(state: State, val: any) {
            state.list.name = val
            state.list.date = new Date().toISOString()
            ListStorage.updateList(state.list)
        },
        updateUserUuidList(state: State, list: any) {
            state.list.user_uuid_list = list
        },
        updateActiveList(state: State, list: any) {
            state.list = list
        },
        updateFetchStatus(state: State, bool: any) {
            state.list.isFetched = bool
        },
        updateTags(state: State) {
            ListStorage.updateList(state.list)
        },
        addTag(state: State, tag: any) {
            state.list.tags.push(tag)
            ListStorage.updateList(state.list)
        },
        removeTag(state: State, tag: any) {
            state.list.tags.splice(state.list.tags.indexOf(tag), 1)
            ListStorage.updateList(state.list)
        },
        createList(state: State) {
            state.list.id = ListStorage.generateId()
        },
        resetActiveList(state: State) {
            state.list = {
                ...DefaultList
            }
        }
    },
    actions: {
        syncUserList({ commit, state }: { commit: Function; state: State }) {
            return new Promise((resolve, reject) => {
                if (state.list.isFetched) return resolve(state.users)
                const list = state.list.user_uuid_list
                if (list.length == 0) return reject()
                Api.post("/admn/user_finder/", { user_list: list.join(",") }).then(
                    res => {
                        Api.parseCsv(res, (parsedData: any) => {
                            commit("updateUsers", parsedData)
                            commit("updateFetchStatus", true)
                            resolve(parsedData)
                        })
                    },
                    err => reject(err)
                )
            })
        }
    },
    getters: {
        getUsersUuid: (state: any) => state.list.user_uuid_list,
        getListName: (state: any) => state.list.name,
        getAcitveList: (state: any) => state.list,
        getListTags: (state: any) => state.list.tags
    }
}
export default CALL_INTERFACE
