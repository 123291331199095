<template>
    <div id="app">
        <v-app>
            <v-locale-provider>
                <AppHeader v-if="authStore.adminUser" />
                <BaseHeader v-else />
                <main class="app__main display-under-body">
                    <AppMenu v-if="authStore.adminUser" />
                    <router-view class="web-app-router-view" />
                </main>
            </v-locale-provider>
        </v-app>
    </div>
</template>

<script setup lang="ts">
import BaseHeader from "@/components/core/BaseHeader.vue"
import AppHeader from "@/components/global/AppHeader.vue"
import AppMenu from "@/components/global/AppMenu.vue"
import { useAuthStore } from "./store/core/auth"

const authStore = useAuthStore()
</script>

<style lang="scss">
@import "@/styles/index";
@import "@/styles/login";
.app__main {
    height: calc(100vh - $header-height);
    max-height: calc(100vh - $header-height);
    width: 100vw;
    display: flex !important;
}

.web-app-router-view {
    flex: 1;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    scroll-behavior: smooth;
    overflow-anchor: none;
    margin: 0 auto;
}
</style>
