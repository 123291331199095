import { defineStore } from "pinia"
import { ref } from "vue"

export const useIndexStore = defineStore("index", () => {
    const pageDescription = ref<string>()
    const isFromUserFinder = ref<boolean>(false)
    return {
        pageDescription,
        isFromUserFinder
    }
})
