const TransitPass = () => import("@/views/companiesAndTerritories/TransitPass.vue")

const moduleBasePath = "/admin/transit-pass"

const routes = [
    {
        path: `${moduleBasePath}`,
        name: "transitPassIndex",
        component: TransitPass,
        meta: {
            label: "Transit pass"
        }
    }
]

export default routes
