const PartnerIndex = () => import("@/views/companiesAndTerritories/partners/PartnerIndex.vue")
const PartnerTerritoryIndex = () => import("@/views/companiesAndTerritories/partners/PartnersTerritoryIndex.vue")
const PartnerHoldingIndex = () => import("@/views/companiesAndTerritories/partners/PartnersHoldingIndex.vue")
const AddBulkPartners = () => import("@/views/companiesAndTerritories/AddBulkPartners.vue")

const moduleBasePath = "/admin/partners/"
const routes = [
    {
        path: `${moduleBasePath}`,
        name: "partnersIndex",
        component: PartnerIndex,
        meta: {
            label: "Partners"
        },
        children: [
            {
                path: "territory/:id?",
                component: PartnerTerritoryIndex,
                name: "partnerTerritoryIndex"
            },
            {
                path: "holding/:id?/:affiliateId?",
                component: PartnerHoldingIndex,
                name: "partnerHoldingIndex"
            }
        ]
    },
    {
        path: "/admin/add-bulk-partners",
        name: "addBulkPartners",
        component: AddBulkPartners,
        meta: {
            label: "Add bulk partners"
        }
    }
]

export default routes
