const FlashInfos = () => import("@/views/crm/FlashInfos.vue")

const moduleBasePath = "/admin/flash_info/"

const routes = [
    {
        path: `${moduleBasePath}:id?`,
        name: "flashInfoIndex",
        component: FlashInfos,
        meta: {
            label: "Newsflash"
        }
    }
]

export default routes
