interface State {
    pois: Array<any>
}

const POIS = {
    state: {
        pois: []
    } as State,
    mutations: {
        updatePois(state: State, list: any) {
            state.pois = list
        }
    },
    getters: {
        getPois: (state: State) => state.pois
    }
}
export default POIS
