const PricingRules = () => import("@/views/companiesAndTerritories/PricingRules.vue")
const EnterprisesPricingRulesTable = () =>
    import("@/components/companiesAndTerritories/pricingRules/enterprise/EnterprisesPricingRulesTable.vue")
const TerritoriesPricingRulesTable = () =>
    import("@/components/companiesAndTerritories/pricingRules/territory/TerritoriesPricingRulesTable.vue")

const moduleBasePath = "/admin/pricing_rules/"

const routes = [
    {
        path: `${moduleBasePath}`,
        name: "princingRulesIndex",
        component: PricingRules,
        redirect: { name: "princingRulesIndexTerritory" },
        meta: {
            label: "Pricing rules"
        },
        children: [
            {
                path: `company/:id?`,
                name: "princingRulesIndexCompany",
                component: EnterprisesPricingRulesTable
            },
            {
                path: `territory/:id?`,
                name: "princingRulesIndexTerritory",
                component: TerritoriesPricingRulesTable
            }
        ]
    }
]

export default routes
