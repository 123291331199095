const AccountManagerProfiles = () => import("@/views/companiesAndTerritories/AccountManagerProfiles.vue")

const moduleBasePath = "/admin/account_managers/"

const routes = [
    {
        path: `${moduleBasePath}`,
        name: "accountManagerIndex",
        component: AccountManagerProfiles,
        meta: {
            label: "Account Manager profiles"
        }
    }
]

export default routes
