const MobilityPackageIndex = () => import("@/views/companiesAndTerritories/mobilityPackage/MobilityPackageIndex.vue")

const moduleBasePath = "/admin/mobility-package"

export default [
    {
        path: `${moduleBasePath}`,
        name: "mobilityPackageIndex",
        component: MobilityPackageIndex,
        meta: {
            label: "Mobility Package"
        }
    }
]
