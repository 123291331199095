const PartnerMapIndex = () => import("@/views/companiesAndTerritories/PartnerMapIndex.vue")

const routes = [
    {
        path: "/admin/partner-map",
        component: PartnerMapIndex,
        name: "partnerMapIndex",
        meta: {
            label: "Partner map"
        }
    }
]

export default routes
