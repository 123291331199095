const BatchNotifications = () => import("@/views/crm/batchNotif/Index.vue")

export default [
    {
        path: "/admin/batch-notifications/",
        component: BatchNotifications,
        name: "BatchNotificationsIndex",
        meta: {
            label: "Batch notif"
        }
    }
]
