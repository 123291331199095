import { Currency, UserInterest } from "@/models/core"

export const DISPLAY_DATE_FORMAT = "DD-MM-YYYY"
export const DISPLAY_DATETIME_FORMAT = "DD-MM-YYYY HH:mm"
export const VALUE_DATE_FORMAT = "YYYY-MM-DD"
export const VALUE_DATETIME_FORMAT = "YYYY-MM-DDTHH:mm:ss.sssZ"
export const CURRENCY_LABELS: Record<Currency, string> = {
    EUR: "€",
    DKK: "kr.",
    CHF: "Fr."
}

export const GRADIENT_ENTERPRISE = "linear-gradient(90deg, rgba(56, 179, 255, 1) 0%, rgba(20, 63, 128, 1) 100%)"
export const GRADIENT_TERRITORY = "linear-gradient(90deg, rgba(127, 211, 197, 1) 0%, rgba(60, 148, 128, 1) 100%)"
export const PATHS_WITHOUT_AUTH_REGEX = /login|reset_password/

export const INTEREST_MAP: Record<UserInterest, string> = {
    both: "Both",
    passenger: "Passenger",
    driver: "Driver"
}
