<template>
    <div class="loader-container display-over-everything">
        <v-progress-circular indeterminate color="primary" />
    </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.loader-container {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    display: grid;
    place-content: center;
}
</style>
