import { RouteRecordRaw } from "vue-router"

const TicketingIndex = () => import("@/views/crm/ticketing/TicketingIndex.vue")
const TicketingSettingsIndex = () => import("@/views/crm/ticketing/TicketingSettingsIndex.vue")
const TicketingSettingsMacros = () => import("@/views/crm/ticketing/TicketingSettingsMacros.vue")
const TicketingSettingsTags = () => import("@/views/crm/ticketing/TicketingSettingsTags.vue")

export default [
    {
        path: "/admin/ticketing/list/:group/:ticketId(\\d+)?",
        name: "ticketingIndex",
        component: TicketingIndex
    },
    {
        path: "/admin/ticketing/list/",
        redirect: "/admin/ticketing/list/my-tickets",
        meta: {
            label: "Ticketing"
        }
    },
    {
        path: "/admin/ticketing/settings",
        name: "ticketingSettings",
        component: TicketingSettingsIndex,
        redirect: { name: "ticketingSettingsMacros" },
        children: [
            {
                path: "macros/:macroId?",
                name: "ticketingSettingsMacros",
                component: TicketingSettingsMacros
            },
            {
                path: "tags/",
                name: "ticketingSettingsTags",
                component: TicketingSettingsTags
            }
        ]
    }
] as RouteRecordRaw[]
