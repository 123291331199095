const UserFinder = () => import("@/components/global/UserFinder.vue")
const BackendDocumentation = () => import("@/views/various/BackendDocumentation.vue")

export default [
    {
        path: "/admin/user-finder",
        name: "userFinder",
        component: UserFinder,
        meta: {
            label: "User finder"
        }
    },
    {
        path: "/admin/doc/:docProvider(swagger-ui|redoc)",
        name: "docIndex",
        component: BackendDocumentation,
        meta: {
            label: "Karos API documentation"
        }
    }
]
