const PartnerMatchListing = () => import("@/views/companiesAndTerritories/PartnerMatchListing.vue")

const routes = [
    {
        path: "/admin/partner-match-listing",
        name: "partnerMatchListing",
        component: PartnerMatchListing,
        meta: {
            label: "Partner match listing"
        }
    }
]

export default routes
