const Index = () => import("@/views/crm/callInterface/Index.vue")
const List = () => import("@/views/crm/callInterface/List.vue")
const UserDetails = () => import("@/views/crm/callInterface/UserDetails.vue")
const CallInterfaceUserFinder = () => import("@/views/crm/callInterface/CallInterfaceUserFinder.vue")
import { store } from "@/store/index"

const moduleBasePath = "/admin/call_interface"

const CALL_INTERFACE_INDEX = {
    path: `${moduleBasePath}`,
    name: "callInterfaceIndex",
    component: Index,
    meta: {
        label: "Call interface"
    }
}
const CALL_INTERFACE_LIST = {
    path: `${moduleBasePath}/list`,
    name: "callInterfaceList",
    component: List,
    meta: {
        label: "Call interface > List"
    }
}
const CALL_INTERFACE_USER_DETAILS = {
    path: `${moduleBasePath}/user/:userId`,
    name: "user",
    component: UserDetails,
    meta: {
        label: "User details"
    },
    beforeEnter: (to: any, from: any, next: any) => {
        const uuid = to.params.userId
        store.commit("updateActiveUserUuid", uuid)
        next()
    }
}
const CALL_INTERFACE_USER_FINDER = {
    path: `${moduleBasePath}/user-finder`,
    name: "user-finder",
    component: CallInterfaceUserFinder,
    meta: {
        label: "User finder"
    }
}

const routes = [CALL_INTERFACE_INDEX, CALL_INTERFACE_LIST, CALL_INTERFACE_USER_DETAILS, CALL_INTERFACE_USER_FINDER]

export default routes
