const PricingAreas = () => import("@/views/various/PricingAreas.vue")

export default [
    {
        path: `/admin/pricing_areas`,
        name: "pricingAreas",
        component: PricingAreas,
        meta: {
            label: "Pricing areas"
        }
    }
]
