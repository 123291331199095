const AdminUser = () => import("@/views/other/adminUser/AdminUserIndex.vue")
const moduleBasePath = "/admin/admin_user/"

const routes = [
    {
        path: `${moduleBasePath}`,
        name: "adminUserIndex",
        component: AdminUser,
        meta: {
            label: "Admin user"
        }
    }
]

export default routes
