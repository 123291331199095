// Needs to be re-facto with call_interface.js as a user & user-list manager
import Api from "@/lib/Api"
import { UserCee } from "@/models/user/user"

interface State {
    users: Array<any>
    activeUserUuid: any
}

const USERS = {
    state: {
        users: [],
        activeUserUuid: ""
    } as State,
    actions: {
        fetchUser({ commit, state }: { commit: Function; state: State }, user_uuid: any) {
            return new Promise((resolve, reject) => {
                Api.get(`/admn/users/${user_uuid}/`).then(
                    res => {
                        resolve(res)
                    },
                    err => reject(err)
                )
            })
        },
        getUserCee({}, userUuid: string): Promise<UserCee> {
            return new Promise((resolve, reject) => {
                Api.get(`/admn/user_cee/${userUuid}/`)
                    .then((userCee: UserCee) => {
                        resolve(userCee)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        searchUsers({}, { kw, searchFilterType }: any) {
            return new Promise((resolve, reject) => {
                Api.get(`/admn/user_search/?kw=${kw}&searchFilterType=${searchFilterType}`)
                    .then(users => {
                        resolve(users)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },
        getUserDetails({}, userUuid: any) {
            return new Promise((resolve, reject) => {
                Api.get(`/admn/users/${userUuid}/`)
                    .then(userDetails => {
                        resolve(userDetails)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },

        getUserCalls({}, userUuid: any) {
            return new Promise((resolve, reject) => {
                Api.get(`/admn/calls/?user_uuid=${userUuid}`)
                    .then(userCalls => {
                        resolve(userCalls)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },
        createCall({}, call: any) {
            return new Promise((resolve, reject) => {
                Api.post("/admn/calls/", call)
                    .then(createdCall => {
                        resolve(createdCall)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },

        getUserNotes({}, userUuid: any) {
            return new Promise((resolve, reject) => {
                Api.get(`/admn/notes/?user_uuid=${userUuid}`)
                    .then(userNotes => {
                        resolve(userNotes)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },
        createUserNote({}, { note, userUuid }: any) {
            return new Promise((resolve, reject) => {
                Api.post(`/admn/notes/?user_uuid=${userUuid}`, note)
                    .then(createdNote => {
                        resolve(createdNote)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },

        getUserGifts({}, userId: any) {
            return new Promise((resolve, reject) => {
                Api.get(`/gifts/?user_id=${userId}`)
                    .then(gifts => {
                        resolve(gifts)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },

        getUserLastMatchesStopped({}, userUuid: any) {
            return new Promise((resolve, reject) => {
                Api.get(`/admn/last_matches_stopped/?user_uuid=${userUuid}`)
                    .then(userLastMatchesStopped => {
                        resolve(userLastMatchesStopped)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },

        getDriverLicensesToValidate({}) {
            return new Promise((resolve, reject) => {
                Api.get(`/admn/driver_license_manual_validation/`)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },

        updateDriverLicense({}, params: object) {
            return new Promise((resolve, reject) => {
                Api.post(`/admn/driver_license_manual_validation/`, params)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },

        setDriveLicenseToManualValidation({}, documentId: number) {
            return new Promise((resolve, reject) => {
                Api.post(`/admn/driver_license_manual_validation/${documentId}/assign/`)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },

        updateBonusState({}, userUuid: string) {
            return new Promise((resolve, reject) => {
                const params = {
                    user_uuid: userUuid
                }
                Api.post(`/admn/cee/update_bonus/`, params)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        }
    },
    mutations: {
        updateUsers(state: State, list: any) {
            state.users = list
        },
        clearUsers(state: State) {
            state.users = []
        },
        updateActiveUserUuid(state: State, uuid: any) {
            state.activeUserUuid = uuid
        }
    },
    getters: {
        getUsers: (state: State) => state.users,
        getUser: (state: State) => (user_uuid: any) => {
            return state.users.find(user => user.user_uuid === user_uuid)
        },
        getActiveUser: (state: State) => state.users.find(el => el.user_uuid === state.activeUserUuid)
    }
}
export default USERS
