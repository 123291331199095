const RemoteConfig = () => import("@/views/crm/RemoteConfig.vue")

const moduleBasePath = "/admin/remote_config/"

const routes = [
    {
        path: `${moduleBasePath}`,
        name: "remoteConfigIndex",
        component: RemoteConfig,
        meta: {
            label: "Remote Configs"
        }
    }
]

export default routes
