import Api from "@/lib/Api"

const USER_MAP = {
    mutation: {},
    actions: {
        syncUserMap({}, args: any) {
            return new Promise((resolve, reject) => {
                Api.post("/user_map/", args).then(
                    res => {
                        resolve(res)
                    },
                    err => reject(err)
                )
            })
        }
    }
}

export default USER_MAP
