import { createVuetify } from "vuetify"
import "vuetify/styles"
import { aliases, mdi } from "vuetify/iconsets/mdi"
import "@mdi/font/css/materialdesignicons.css"

const vuetify = createVuetify({
    icons: {
        defaultSet: "mdi",
        aliases,
        sets: {
            mdi
        }
    },
    theme: {
        defaultTheme: "webAppTheme",
        themes: {
            webAppTheme: {
                colors: {
                    primary: "#2196F3",
                    "admin-outline": "#E0E0E0",
                    "black-secondary": "#666666",
                    "grey-secondary": "#D5D5D5",
                    "surface-2": "#F2F7FA"
                }
            }
        }
    },
    defaults: {
        VTextField: {
            color: "primary"
        },
        VTextarea: {
            color: "primary"
        },
        VSwitch: {
            color: "primary"
        }
    }
})

export default vuetify
