const CrmUserFinder = () => import("@/views/crm/CrmUserFinder.vue")

const metaData = {
    label: "User finder"
}

const userFinder = [
    {
        path: "/admin/user_finder",
        name: "crmUserFinder",
        component: CrmUserFinder,
        meta: metaData
    }
]

export default userFinder
