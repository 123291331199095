const BatchMessages = () => import("@/views/crm/BatchMessages.vue")

const moduleBasePath = "/admin/chat_batch"

const routes = [
    {
        path: `${moduleBasePath}`,
        name: "chatBatchIndex",
        component: BatchMessages,
        meta: {
            label: "Batch messages"
        }
    }
]

export default routes
