import api from "@/lib/Api"

export default {
    actions: {
        getRemoteConfig() {
            return new Promise((resolve, reject) => {
                api.get("/admn/remote_config/")
                    .then(remoteConfig => {
                        resolve(remoteConfig)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        }
    }
}
