const UserIndex = () => import("@/views/user/UserIndex.vue")
const UserTabProfile = () => import("@/views/user/tabs/UserTabProfile.vue")
const UserTabCee = () => import("@/views/user/tabs/UserTabCee.vue")
const UserTabWallet = () => import("@/views/user/tabs/UserTabWallet.vue")

export default [
    {
        path: "/admin/users-v2/:userUuid?",
        name: "userIndex",
        component: UserIndex,
        meta: {
            label: "User"
        },
        children: [
            {
                path: "profile",
                name: "userProfile",
                component: UserTabProfile
            },
            {
                path: "cee",
                name: "userCee",
                component: UserTabCee
            },
            {
                path: "wallet",
                name: "userWallet",
                component: UserTabWallet
            }
        ]
    }
]
