import { createStore } from "vuex"
import List from "@/store/modules/list"
import CallHistory from "@/store/modules/call_history"
import Pois from "@/store/modules/pois"
import Users from "@/store/modules/users"
import remoteConfigs from "@/store/modules/remoteConfigs"

/* CRM */
import tickets from "@/store/crm/tickets"
import transitPassValidation from "@/store/crm/transitPass"
import FlashInfos from "@/store/crm/flashInfos"

/* Companies and territories */
import Partners from "@/store/companiesAndTerritories/partners"
import pricing from "@/store/companiesAndTerritories/pricing"

/* Other */
import shareConfig from "@/store/other/shareConfig"
import userMap from "@/store/other/userMap"
import karosEvent from "@/store/other/karosEvent"

/* Match */
import match from "@/store/match/match"

/* Various */
import chatroomMessages from "@/store/various/userChatroom"
import userFinder from "@/store/various/userFinder"

export const store = createStore({
    modules: {
        List,
        CallHistory,
        Pois,
        Users,
        FlashInfos,
        tickets,
        Partners,
        pricing,
        transitPassValidation,
        shareConfig,
        remoteConfigs,
        userMap,
        karosEvent,
        chatroomMessages,
        userFinder,
        match
    }
})
