import Api from "@/lib/Api"

interface State {
    callHistory: Array<any>
}

const CALL_HISTORY = {
    state: {
        callHistory: []
    } as State,
    mutations: {
        updateCallHistory(state: State, calls: any) {
            state.callHistory = calls
        }
    },
    actions: {
        syncCallHistory({
            commit,
            state,
            rootState
        }: {
            commit: Function
            state: State
            rootState: any
        }): Promise<void> {
            return new Promise((resolve, reject) => {
                Api.get("/admn/calls/?user_uuid=" + rootState.Users.activeUserUuid).then(
                    (res: any) => {
                        res.sort((a: any, b: any) => {
                            return new Date(b.date).getTime() - new Date(a.date).getTime()
                        })
                        commit("updateCallHistory", res)
                        resolve()
                    },
                    err => reject
                )
            })
        }
    },
    getters: {
        getCallHistory: (state: State) => state.callHistory
    }
}
export default CALL_HISTORY
