const CarpoolPlace = () => import("@/views/other/carpoolPlace/CarpoolPlaceIndex.vue")

export default [
    {
        path: `/admin/carpool-place`,
        name: "carpoolPlace",
        component: CarpoolPlace,
        meta: {
            label: "Carpool Spot & Area"
        }
    }
]
