const UserMap = () => import("@/views/other/UserMap.vue")

const moduleBasePath = "/admin/user_map"

const routes = [
    {
        path: `${moduleBasePath}`,
        name: "userMapIndex",
        component: UserMap,
        meta: {
            label: "User map"
        }
    }
]

export default routes
