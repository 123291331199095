import { MenuType } from "@/constants/enums"
import { Challenge } from "@/models/crm/challenge"
import { AssigneeChangedEventPayload, ChatMessage, TicketDetails } from "@/models/crm/ticketing"
import { CascadeOption } from "@/models/core"
import mitt from "mitt"

export const cascadeOptionEventHub = mitt<{
    "cascade-select-item": CascadeOption
}>()

export const challengeEventHub = mitt<{
    "edit-challenge": Challenge
}>()

export const CeeAuditEventHub = mitt<{
    "close-panel": undefined
}>()

export const CarpoolPlaceEventHub = mitt<{
    "display-import-modal": boolean
}>()

export const ticketingEventHub = mitt<{
    "ticket-details-ready": number
    "close-ticket-details-tab": TicketDetails
    "scroll-to-latest-message": void
    "refresh-ticketing-datas": void
    "assignee-changed": AssigneeChangedEventPayload
    "file-session-opened": ChatMessage
}>()

export const matchEventHub = mitt<{
    "refresh-payment-events": void
}>()

export const appMenuEventHub = mitt<{
    "menu-changed": MenuType
}>()

const eventHub = mitt()
export default eventHub
