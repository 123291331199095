const AppReleaseAndEula = () => import("@/views/other/AppReleaseAndEula.vue")

const moduleBasePath = "/admin/app_release_eula/"

const routes = [
    {
        path: `${moduleBasePath}`,
        name: "appReleaseEula",
        component: AppReleaseAndEula,
        meta: {
            label: "App release & EULA"
        }
    }
]

export default routes
