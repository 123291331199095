<template>
    <div class="ticketing-menu">
        <v-list nav density="compact">
            <v-list-item value="back" prepend-icon="mdi-arrow-left-circle" rounded="lg" @click="updateMenuType()">
                <v-list-item-title class="text-subtitle-2">Back to menu</v-list-item-title>
            </v-list-item>
        </v-list>
        <v-divider class="mx-4" />
        <v-list nav density="compact">
            <v-list-item class="drawer__title text-subtitle-6 font-weight-bold">
                {{ menuStore.isMenuExpanded ? "Tickets" : "" }}
            </v-list-item>
            <v-list-item
                v-for="[group, routeConf] in constants.TICKETING_CONF_PER_GROUP"
                :to="{ name: 'ticketingIndex', params: { group } }"
                class="text-subtitle-2 rounded-lg menu-item"
                color="primary"
                :active="ticketGroup === group"
                data-testid="menu-link-item">
                <template #prepend>
                    <v-icon :icon="getIconToDisplay(group, routeConf)" />
                </template>
                <span class="menu-item__label" v-if="menuStore.isMenuExpanded" data-testid="list-item-text">
                    {{ routeConf.shortTitle }}
                </span>
                <template #append>
                    <span v-if="menuStore.isMenuExpanded && store.ticketingStats" data-testid="count-tickets-text">
                        {{ store.ticketingStats[routeConf.countKey] }}
                    </span>
                </template>
            </v-list-item>
        </v-list>

        <v-spacer />

        <v-list nav density="compact">
            <v-list-item
                rounded="lg"
                prepend-icon="mdi-cog"
                :active="route.path.includes('/admin/ticketing/settings')"
                color="primary"
                :to="{ name: 'ticketingSettings' }"
                class="text-subtitle-2">
                Settings
            </v-list-item>
        </v-list>
    </div>
</template>

<script setup lang="ts">
import { TicketingRouteConf } from "@/models/crm/ticketing"
import constants from "@/constants/crm/ticketing"
import { useTicketingStore } from "@/store/crm/ticketing"
import { useTicketingGroup } from "@/composable/router"
import { useMenuStore } from "@/store/core/menu"
import { useRoute } from "vue-router"

const route = useRoute()

const store = useTicketingStore()
const menuStore = useMenuStore()
const { ticketGroup } = useTicketingGroup()

const getIconToDisplay = (navigationItemRouteName: string, routeConf: TicketingRouteConf) => {
    return ticketGroup.value === navigationItemRouteName ? routeConf.icon : `${routeConf.icon}-outline`
}

const emits = defineEmits(["update-menu-type"])
const updateMenuType = (): void => {
    emits("update-menu-type")
}
</script>

<style lang="scss" scoped>
.ticketing-menu {
    display: flex;
    flex-direction: column;
}
.drawer__title {
    margin: 20px 0 8px 0;
}
.menu-item {
    &:first-child {
        margin-top: 20px;
    }
    .menu-item__label {
        white-space: nowrap;
    }
}
</style>
