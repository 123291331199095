const KarosEventIndex = () => import("@/views/other/karosEvent/KarosEventIndex.vue")
const KarosEventList = () => import("@/views/other/karosEvent/KarosEventList.vue")
const KarosEventForm = () => import("@/views/other/karosEvent/KarosEventForm.vue")
const KarosEventAddMany = () => import("@/views/other/karosEvent/KarosEventAddMany.vue")

const moduleBasePath = "/admin/karos-event"
const metaData = {
    label: "Events"
}

export default [
    {
        path: `${moduleBasePath}`,
        redirect: `${moduleBasePath}/form`,
        name: "karosEventIndex",
        component: KarosEventIndex,
        meta: metaData,
        children: [
            {
                path: "form",
                component: KarosEventForm,
                name: "formKarosEvent",
                meta: metaData
            },
            {
                path: "past",
                component: KarosEventList,
                name: "pastKarosEventList",
                meta: metaData
            },
            {
                path: "pending",
                component: KarosEventList,
                name: "pendingKarosEventList",
                meta: metaData
            },
            {
                path: "delete",
                component: KarosEventList,
                name: "deleteKarosEventList",
                meta: metaData
            },
            {
                path: "add-many",
                component: KarosEventAddMany,
                name: "addManyKarosEvents",
                meta: metaData
            }
        ]
    }
]
