const Challenges = () => import("@/views/crm/Challenges.vue")

const routes = [
    {
        path: "/admin/challenges",
        name: "challengeIndex",
        component: Challenges,
        meta: {
            label: "Challenges"
        }
    }
]

export default routes
