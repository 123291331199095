const TransitPassValidation = () => import("@/views/crm/TransitPassValidation.vue")

export default [
    {
        path: "/admin/transit-pass-validation",
        component: TransitPassValidation,
        name: "transitPassValidationIndex",
        meta: {
            label: "Transit pass validation"
        }
    }
]
