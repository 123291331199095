const OtpCodes = () => import("@/views/other/OtpCodes.vue")

const moduleBasePath = "/admin/otp_codes/"

const routes = [
    {
        path: `${moduleBasePath}`,
        name: "otpCodes",
        component: OtpCodes,
        meta: {
            label: "OTP Codes"
        }
    }
]

export default routes
