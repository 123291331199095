const ShareConfig = () => import("@/views/other/ShareConfig.vue")

const moduleBasePath = "/admin/share_config/"

const routes = [
    {
        path: `${moduleBasePath}`,
        name: "shareConfigIndex",
        component: ShareConfig,
        meta: {
            label: "Share config"
        }
    }
]

export default routes
