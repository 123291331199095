import Api from "@/lib/Api"

interface State {
    userFinderResult: any
    usedFilters: any
    userFinderResearch: any
    userFinderFavoriteRequests: any
    isRequestReadyToBeFavorited: any
}

export default {
    state: {
        userFinderResult: null,
        usedFilters: null,
        userFinderResearch: null,
        userFinderFavoriteRequests: null,
        isRequestReadyToBeFavorited: false
    },
    mutations: {
        updateUserFinderResult(state: any, result: String) {
            state.userFinderResult = result
        },
        resetUsedFilters(state: State) {
            state.usedFilters = []
            state.isRequestReadyToBeFavorited = false
        },
        addUsedFilter(state: State, filterProp: any) {
            state.usedFilters.push(filterProp)
            state.isRequestReadyToBeFavorited = false
        },
        updateRequestReadyToBeFavorited(state: State, isReady: any) {
            state.isRequestReadyToBeFavorited = isReady
        },
        updateUsedFilters(state: State, filters: any) {
            state.usedFilters = filters
            state.isRequestReadyToBeFavorited = false
        },
        saveUserFinderResearch(state: State, research: any) {
            state.userFinderResearch = research ? { ...research } : null
        },
        updateUserFinderFavoriteRequests(state: State, requests: any) {
            state.userFinderFavoriteRequests = requests
        },
        addUserFinderFavoriteRequests(state: State, request: any) {
            state.userFinderFavoriteRequests.push(request)
        }
    },
    actions: {
        applyUserFinder({ commit }: { commit: Function }, params: any) {
            return new Promise((resolve, reject) => {
                Api.post("/admn/user_finder/", params)
                    .then(users => {
                        commit("updateRequestReadyToBeFavorited", true)
                        commit("updateUserFinderResult", users)
                        resolve(users)
                    })
                    .catch(err => {
                        commit("updateUserFinderResult", null)
                        console.error(err)
                        reject(err)
                    })
            })
        },
        syncUserFinderFavoriteRequest({ commit }: { commit: Function }, params: any) {
            return new Promise((resolve, reject) => {
                Api.get("/admn/user_finder_favorite_requests/")
                    .then(requests => {
                        commit("updateUserFinderFavoriteRequests", requests)
                        resolve(requests)
                    })
                    .catch(err => {
                        commit("updateUserFinderFavoriteRequests", null)
                        console.error(err)
                        reject(err)
                    })
            })
        },
        saveUserFinderFavoriteRequest(
            { dispatch, commit, getters }: { dispatch: Function; commit: Function; getters: any },
            params: any
        ) {
            return new Promise((resolve, reject) => {
                params.used_component_names = getters.getFormattedUsedFilter
                Api.post("/admn/user_finder_favorite_requests/", params)
                    .then(request => {
                        commit("addUserFinderFavoriteRequests", request)
                        resolve(request)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
                    .finally(() => {
                        dispatch("syncUserFinderFavoriteRequest")
                    })
            })
        },
        updateUserFinderFavoriteRequest(
            { dispatch, commit, getters }: { dispatch: Function; commit: Function; getters: any },
            params: any
        ) {
            return new Promise((resolve, reject) => {
                params.used_component_names = getters.getFormattedUsedFilter
                Api.patch(`/admn/user_finder_favorite_requests/${params.id}/`, params)
                    .then(requests => {
                        commit("updateUserFinderFavoriteRequests", requests)
                        resolve(requests)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
                    .finally(() => {
                        dispatch("syncUserFinderFavoriteRequest")
                    })
            })
        },
        deleteFavoriteRequest({ dispatch }: { dispatch: Function }, params: any) {
            return new Promise((resolve, reject) => {
                Api.delete(`/admn/user_finder_favorite_requests/${params.id}/`)
                    .then((requests: any) => {
                        resolve(requests)
                    })
                    .catch((err: any) => {
                        console.error(err)
                        reject(err)
                    })
                    .finally(() => {
                        dispatch("syncUserFinderFavoriteRequest")
                    })
            })
        }
    },
    getters: {
        getUserFinderResult: (state: State) => state.userFinderResult,
        getUsedFilters: (state: State) => state.usedFilters,
        getFormattedUsedFilter: (state: State) => state.usedFilters && state.usedFilters.join(","),
        getUserFinderResearch: (state: State) => state.userFinderResearch,
        getUserFinderGoBackRouteName: (state: State) => state.goBackRouteName,
        getUserFinderFavoriteRequests: (state: State) => state.userFinderFavoriteRequests,
        isRequestReadyToBeFavorited: (state: State) => state.isRequestReadyToBeFavorited
    }
}
